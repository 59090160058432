.search-bar {
    position: relative;
  }
  
  .search-bar:hover .search-field {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .search-field {
    transition: box-shadow 0.3s;
  }
  