/* src/components/BookList/Book.css */

.book-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.book-cover {
  flex-shrink: 0;
  width: 120px;
  height: 180px;
  object-fit: cover;
}
.book-cover-wrapper {
  width: 120px;
  height: 180px;
  overflow: hidden;
}
.book-details-wrapper {
  flex: 1;
  min-width: 200px;
}