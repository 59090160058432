.book-list {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
  }
  
  .book-item {
    transition: transform 0.3s;
  }
  
  .book-item:hover {
    transform: translateY(-5px);
  }
  